body, html {
	font-family: Verdana, Roboto, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	font-family: Verdana, Roboto, sans-serif;
}

p, a, li, span, div {
	font-family: Verdana, Roboto, sans-serif;
}

.floating-ivw-logo {
	display: none;
}

// remove slick control background
.slick-next, .slick-prev {
	background-color: transparent !important;
	background: transparent !important;
}

// remove slick control background
.nav-social-media-links {
	padding-left: rem(14px);

	@include media-breakpoint-up(xl) {
		display: flex !important;
	}

	a {
		margin-left: 1.125rem;
		position: relative;
		overflow: hidden;
		display: block;
		height: 48px;
		width: 48px;

		.mdi {
			justify-content: center;
			align-items: center;
			display: flex;
			color: white;
			line-height: 2.5rem;
			font-size: 2.5rem;
			height: 48px;
			width: 48px;
		}
	}
}

.teaser-link {
	color: white !important;
}

// BLUE BUTTONS

$secondary-color: #1485CC;

.infobox__container .tabs--vertical {
	background: color("shades", "white");

	.tab .active {
		&::before {
			border-top: 3px solid $secondary-color;

			@include media-breakpoint-up(m) {
				border-left: 3px solid $secondary-color;
			}
		}

		.tab__title {
			color: color("grey", "darken-2") !important;
		}

		.btn-large {
			background: $secondary-color;
		}
	}
}

.current-program__icons {
	a {
		background-color: $secondary-color !important;
	}
	i {
		color: white !important;
	}
}

.btn-primary {
	background: $secondary-color !important;
}

.infobox__content {
	.infobox__content__button .btn {
		background: $secondary-color;
	}
}

.floating-share-button, .floating-scroll-button {
	.btn-floating {
		background: $secondary-color !important;
	}
}

.related-news__item {
	.teaser-link {
		color: rgb(69, 69, 69) !important;
	}
}

// TIMELINE FIX
@media (min-width: 48em) {
	.timeline__datetime::after {
		bottom: 35% !important;
	}
}

.timeline__datetime {
	.timeline__time {
		font-size: 1.5rem !important;
		margin-bottom: 0.5rem !important;
	}
}
